<template>
  <b-dropdown-item
    v-if="item.status"
    @click="deactivate()"
  >
    <feather-icon
      icon="ToggleRightIcon"
    />
    {{ deactivateText }}
  </b-dropdown-item>

  <b-dropdown-item
    v-else
    @click="activate()"
  >
    <feather-icon
      icon="ToggleLeftIcon"
    />
    {{ activateText }}
  </b-dropdown-item>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ToggleStatus',
  props: {
    item: { type: Object, default: () => {} },
    activateText: { type: String, default: 'Activate' },
    deactivateText: { type: String, default: 'Deactivate' },
    activateUrl: { type: String, default: '' },
    deactivateUrl: { type: String, default: '' },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return {
      successfulOperationAlert,
    }
  },
  methods: {
    activate() {
      this.$entities.post(`${this.activateUrl}${this.item.id}`).then(() => {
        this.successfulOperationAlert('Activated Successfully')
        this.item.status = !this.item.status
      })
    },
    deactivate() {
      this.$entities.post(`${this.deactivateUrl}${this.item.id}`).then(() => {
        this.successfulOperationAlert('Deactivated Successfully')
        this.item.status = !this.item.status
      })
    },
  },
}
</script>
<style lang="scss">

</style>
