<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent
    >
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Name"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="language.language_name"
            :state="getValidationState(validationContext)"
            autofocus
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
        #default="validationContext"
        name="Direction"
        rules="required"
      >
        <b-form-group
          label="Direction"
          label-for="direction"
        >
          <b-form-select
            id="direction"
            v-model="language.text_direction"
            :options="textDirections"
            :state="getValidationState(validationContext)"
            autofocus
          />
        </b-form-group>
      </validation-provider>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <submit-button
          :handle-submit="handleSubmit"
          :submit="updateLanguage"
        />
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'

export default {
  name: 'EditLanguage',
  components: {
    SubmitButton,
  },
  props: {
    textDirections: { type: Array, default: () => [] },
    language: { type: Object, default: () => {} },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert } = handleAlerts()
    return {
      successfulOperationAlert, getValidationState,

    }
  },
  methods: {
    updateLanguage() {
      return this.$entities.post(`internalops/languages/${this.language.id}?_method=PUT`, {
        language_name: this.language.language_name,
        text_direction: this.language.text_direction,
      }).then(() => {
        this.$bvModal.hide('edit-modal')
        this.successfulOperationAlert('Language is edited successfully')
        this.$emit('refresh')
      })
    },
  },
}
</script>
